module.exports = [ '$scope', '$q', 'constants', 'settings', '$location', 'Survey',
  function ($scope, $q, constants, settings, $location, Survey) { //eslint-disable-line
    'use strict';

    $scope.stepNumber = 1;
    $scope.$emit('wellbe_wizard_step', {stepNumber: $scope.stepNumber});   

    $scope.headerTitle = 'Intake';
    $scope.headerDescription = 'Je hebt met de directeur beslist om deze bevraging toch zelf af te nemen. Wil je een korte motivatie invullen ?';

    Survey.getSurvey().then(survey => {
      delete survey.motivation; // remove possible '...', will never reach this screen if the user already filled it with other value
      $scope.survey = survey;            
    })

    $scope.onNext = () => {

      // saveSurvey with updated motivation text
      Survey.saveSurvey($scope.survey).then(() => $location.path('/enddate'));      
    };
  }];
