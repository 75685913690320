  
class EndDate {
  
  constructor($rootScope, $scope, $location, dateUtils, Utils, Survey){
    
    this.$location = $location;
    this.dateUtils = dateUtils;
    this.Survey = Survey;

    this.manualHref = $rootScope.manualHref;
    
    this.stepNumber = 2;
    $scope.$emit('wellbe_wizard_step', {stepNumber: this.stepNumber});
  
    this.headerTitle = 'Einddatum';
    this.headerDescription = 'Tot wanneer kunnen de deelnemers de enquête invullen?';
  
    // datepicker allows only dates in the future
    this.startDate = Utils.dateToString(new Date());    
    
    Survey.getSurvey().then(survey => {
      this.survey = survey;
      if (this.survey) {
        const endDateCleared = dateUtils.stripTime(this.survey.enddate);
        this.survey.enddate = endDateCleared;
      }
    });
  }

  onNext(scope, e, arg) {
    
    if (scope.ctrl.survey && scope.ctrl.survey.enddate) {
      let d = (new Date(scope.ctrl.dateUtils.stripTime(scope.ctrl.survey.enddate) + 'T19:00:00.000Z'))
      d.setHours(12);
      scope.ctrl.survey.enddate = d.toISOString();
  
      try {
        scope.ctrl.Survey.saveSurvey(scope.ctrl.survey)
          .then(() => scope.ctrl.$location.path('/groups'));
      } catch(ex) {
        // TODO check ex type
        console.log(ex);
      }
      
    } else {
      // show error message, missing enddate selection
      scope.ctrl.feedbackMessage = 'De einddatum moet in de toekomst liggen.';
    }
  }

  back(scope) {
    scope.ctrl.$location.path('/survey-selection');
  }

  $onInit() {
    return (() => {
      
    })();
  }

  // injection here
  // static get $inject() {
  //   return [
  //     '$rootScope',
  //     '$scope',
  //     '$location',
  //     'dateUtils',
  //     'Utils',
  //     'Survey'
  //   ];
  // }
}

EndDate.$inject = ['$rootScope', '$scope', '$location', 'dateUtils', 'Utils', 'Survey'];

module.exports = {
  template: require('./enddate.html'),
  controllerAs: 'ctrl',
  controller: EndDate
};