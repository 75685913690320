module.exports = ['$scope', '$q', '$location', 'WizardState', 'Survey', 'sriClient', 'Utils', 'Organisations',
  async function ($scope, $q, $location, WizardState, Survey, sriClient, Utils, Organisations) { //eslint-disable-line


    $scope.$emit('wellbe_wizard_visibility', { visible: false });
    $scope.$emit('wellbe_header_logoVisibility', { visible: true });

    Survey.clear();

    $scope.schools = await Organisations.getRelevantOrgUnitsForUser();

    const userHasBoardings = $scope.schools.some(school => school.level === 'BOARDING');
    const userHasSchools = $scope.schools.some(school => ['PRIMARY', 'SECONDARY', 'CVO'].includes(school.level));
    if (userHasSchools && userHasBoardings) {
      $scope.institutionText = ['Welke school of internaat', 'school of een internaat', 'de school of van het internaat'];
    } else if (userHasBoardings) {
      $scope.institutionText = ['Welk internaat', 'internaat', 'het internaat'];
    } else {
      $scope.institutionText = ['Welke school', 'school', 'de school'];
    }

    $scope.onChooseSchool = function (school) {
      console.log('on choose school:', school.key);

      Survey.setSchool(school);

      // physical location should be chosen if more than 1 for selected school entity (#15577)
      if (school.locations && school.locations.length > 1) {
        $location.path('/locations');
      } else {
        if (school.locations && school.locations.length === 1) {
          Survey.setPhysicalLocation(school.locations[0].physicalLocation.$$expanded);
        }
        $location.path('/directors');
      }
    };
  }];
