/**
 * The index of the application (where it all starts) for webpack
 */

// BOOTSTRAP (kind of ugly, because it expects jQuery)
window.$ = window.jQuery = require('jquery'); //eslint-disable-line
require('../module/bootstrap/');

var { settings } = require('../config/settings.js');

var angular = require('angular'); // eslint-disable-line

var app = require('./app.js');
app.constant('settings', settings);

app.constant('constants', require('../config/constants.js'));
app.constant('questions', require('../config/questions.js'));

app.constant('dateUtils', require('@kathondvla/sri-client/date-utils'));

app.config(require('./config'));


// Oauth
app.config(['$httpProvider', function ($httpProvider) {
  $httpProvider.defaults.useXDomain = true;
  delete $httpProvider.defaults.headers.common['X-Requested-With'];
  $httpProvider.interceptors.push('OauthInterceptor');
}]).factory('OauthInterceptor', require('vsko-angular-oauth').interceptor);

app.run(['amMoment', 'sriClientConfiguration', function (amMoment, sriClientConfiguration) {
  amMoment.changeLocale('nl');

  sriClientConfiguration.set({
    baseUrl: settings.apisAndUrls.api,
    logging: 'debug'
  });

  if (typeof window.CustomEvent !== 'function') {
    // Polyfill Event in IE old versions (eg.ie11)

    function CustomEvent(event, params) {
      params = params || { bubbles: false, cancelable: false, detail: undefined };
      var evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.Event = CustomEvent;
  }
}]);

module.exports = app;
