  
class Locations {
  
  constructor($rootScope, $location, Utils, Survey){
    
    this.Survey = Survey;
    this.$location = $location;
    this.Utils = Utils;

    this.manualHref = $rootScope.manualHref;
  }

  onChooseLocation(location) {

    if (location) {
      this.Survey.setPhysicalLocation(location);
    }

    this.$location.path('/directors');
  }

  $onInit() {
    return (() => {
      this.locations = this.Survey.getSchool().locations.map(location => {
        location.physicalLocation.$$expanded.display = this.Utils.getLocationAddress(location.physicalLocation.$$expanded);
        return location.physicalLocation.$$expanded
      });
    })();
  }
}

Locations.$inject = ['$rootScope', '$location', 'Utils', 'Survey'];

module.exports = {
  template: require('./locations.html'),
  controllerAs: 'ctrl',
  controller: Locations
};