module.exports = ['$scope', '$rootScope', '$q', '$location', 'Survey', 'Organisations', 'Utils', 'dateUtils', 'kovSentry',
  async function ($scope, $rootScope, $q, $location, Survey, Organisations, Utils, dateUtils, kovSentry) { //eslint-disable-line


    $scope.$emit('wellbe_wizard_visibility', { visible: false });
    $scope.$emit('wellbe_header_logoVisibility', { visible: true });

    Survey.clear();

    kovSentry.startLog('SURVEY SELECTION', {
      username: $rootScope.user.username,
      id: $rootScope.user.key
    });

    $scope.headerTitle = 'Bevraging';
    $scope.headerDescription = "Selecteer de bevraging die je wil bekijken of bewerken. Wil je een nieuwe bevraging starten, druk dan op de knop 'nieuwe bevraging'.";

    // let officialSchoolsObjs = await Survey.getPreventionAdvisorSchoolsObjects()
    const realSchoolsObjs = await Organisations.getRelevantOrgUnitsForUser(true);

    const schools = [];

    realSchoolsObjs.forEach(se => {
      schools.push({ key: se.$$meta.permalink });
    });

    console.log('Schools: ', schools);

    const surveys = await Survey.getSurveys(schools);

    if (surveys && surveys.length > 0) {
      surveys.forEach(async survey => {
        // fill the survey school with the real object from api

        // We set "includeEnded" to true here because we want to include the surveys of "outdated"
        // Governing Institutions in the list of surveys (Monday Ticket => https://katholiek-onderwijs-vlaanderen.monday.com/boards/840381775/pulses/2736511633).

        const school = await Organisations.getLoadedOrganisationalUnit(survey.school.href, true);

        school.name = school.$$displayName;
        // school.address = await Organisations.getAddress(school);

        survey.school.$$expanded = school;
        survey.formattedEnddate = survey.enddate ? Utils.toString(new Date(survey.enddate)) : '-';

        // update the survey percenatge of answers given
        survey.totalParticipants = 0;
        survey.participantsAnswered = 0;
        survey.percentage = 0;

        if (survey.groups && survey.groups.length > 0) {
          survey.participantsAnswered = Utils.countParticipantsAnswered(survey);
          survey.groups.forEach(group => {
            survey.totalParticipants += group.participants ? group.participants.length : 0;
          });

          survey.percentage = Math.round(survey.participantsAnswered * 100 / survey.totalParticipants);
        }
      });

      $scope.surveys = sortSurveys(surveys);
    } else {
      $location.path('/school');
    }

    kovSentry.endLog('SURVEY SELECTION');
    
    $scope.percentageClass = (survey) => {
      let color = '';
      if (survey.percentage) {
        color = +survey.percentage < 40 ? 'red' : (+survey.percentage < 60 ? 'orange' : 'green');
        return 'p' + survey.percentage + ' ' + color;
      }
    };

    $scope.barBackgroundStyle = (survey) => {
      let color = '';
      if (survey.percentage) {
        color = +survey.percentage < 40 ? 'red' : (+survey.percentage < 60 ? 'orange' : 'green');
        return { height: '15px', width: +survey.percentage + '%', 'background-color': color };
      }
    };

    $scope.barBorderStyle = (survey) => {
      let color = '';
      if (survey.percentage) {
        color = +survey.percentage < 40 ? 'red' : (+survey.percentage < 60 ? 'orange' : 'green');
        return { border: '1px solid ' + color };
      }
    };

    $scope.remove = (survey) => {
      console.log('Remove survey', survey);
      survey.deleted = true;
      $scope.surveys = $scope.surveys.filter(s => s.key !== survey.key);
      Survey.deleteSurvey(survey).then(() => {
      });
    };

    $scope.canRemoveSurvey = (survey) => {
      return survey.participantsAnswered === 0
        && (!survey.enddate || dateUtils.isBefore(survey.enddate, dateUtils.getNow()));
    };

    $scope.getLocationAddresses = (survey) => {
      if (!survey.school || !survey.school.$$expanded || !survey.school.$$expanded.locations) {
        return;
      }
      return Utils.getLocationAddresses(survey, survey.school.$$expanded) || survey.school.$$expanded.address;
    };

    /**
     * Sort surveys by end date desc
     */
    function sortSurveys(surveys) {
      return surveys.sort((s1, s2) => {
        return s1.enddate < s2.enddate ? 1 : -1;
      });
    }

    $scope.newSurvey = () => {
      $location.path('/school');
    };

    $scope.openSurvey = (survey) => {
      if (survey.deleted) {
        return;
      }
      console.log('Selected survey:', survey.key, survey);

      Survey.setSurvey(survey, survey.school);

      if (survey && survey.intakeanswers && survey.intakeanswers.length > 0
        && survey.motivation === '...') {
        $location.path('/motivation');
      }
      // compare enddate with current date
      else if (survey && survey.enddate && dateUtils.isAfter(dateUtils.stripTime(new Date().toISOString()), dateUtils.stripTime(survey.enddate))) {
        $location.path('/report-menu');
      } else if (survey && survey.finishedon) { // if finishedon => it's created, all wizard steps done
        $location.path('/status');
      } else if (survey && survey.intakeanswers && survey.intakeanswers.length > 0) {
        $location.path('/enddate');
        // $location.path('/overview');
      } else {
        $location.path('/intake');
      }
    };
  }];
