module.exports = ['$scope', 'settings', '$location', '$timeout', 'vskoModalService', 'constants', 'Utils', 'Survey', 'Reports',
  function ($scope, settings, $location, $timeout, vskoModalService, constants, Utils, Survey, Reports) { //eslint-disable-line

    'use strict';

    let modalInstance;

    $scope.headerTitle = 'Aandachtspunten';
    $scope.headerDescription = '';

    $scope.filter = {
      group: 'ALL',
      situation: constants.reportSituations[0].key,
      options: {}
    };

    let pageBreakConfig = {
      firstPageQuestions: 14,
      otherPagesQuestions: 17
    };

    // init
    Survey.getSurvey().then(survey => {
      // note: should be done before getting the answers
      $scope.components = Reports.generateComponentsStructure();

      $scope.formattedEnddate = Utils.toString(new Date(survey.enddate));

      $scope.survey = survey;

      Survey.getAnswers(survey.key, null, false).then(answers => {
        $scope.answers = answers;
        console.log('Survey answers:', answers);

        Reports.generatePercentageBoxes($scope.answers, $scope.components, null, { situation: $scope.filter.situation });

        groupQuestionsByTip();

        sortTipsQuestions();

        // rule: filter groups with at least 5 participants with answers (#15165)
        $scope.filter.options.groups = $scope.survey.groups.filter(group => {
          return $scope.answers.filter(answer => answer.group === group.key).length >= 5;
        });
      });
    });

    // Filter functions

    $scope.updatedGroupFilter = () => {
      // get the group type from the survey
      $scope.filter.groupType = $scope.filter.group !== 'ALL' ? $scope.survey.groups.filter(g => g.key === $scope.filter.group)[0].type : null;

      // recreate the components structure showing only questions of the selected group
      $scope.components = Reports.generateComponentsStructure($scope.filter.groupType);

      Reports.generatePercentageBoxes(Utils.filterAnswersByGroup($scope.answers, $scope.filter.group), $scope.components, null, { situation: $scope.filter.situation });

      groupQuestionsByTip();

      sortTipsQuestions();
    };


    /**
    * show the question in the report if it has >30% in the sum of negative percentage (TOT_NOT_AGREE, NOT_AGREE) (#15481)
    * and has some tip assigned
    */
    $scope.showQuestion = function (question) {
      if (question.sections && question.sections.length > 1) {
        let sumNegativePercentage = question.sections[0].percentage + question.sections[1].percentage;
        return (question.tipsOrganisation.length > 0 || question.tipsIndividuals.length > 0)
             && sumNegativePercentage >= 33;
      }

      return false;
    };

    $scope.changeView = function () {
      $location.path('/report-attention-points');
    };


    // remove mouseover of other components, questions and overall
    $scope.overQuestion = function (question) {
      $scope.tipTypes.forEach(type => {
        type.tips.forEach(tip => {
          tip.questions.forEach(q => {
            if (q.key != question.key) {
              delete q.mouseover;
              delete q.mouseoverdetails;
            }
          });
        });
      });

      question.mouseover = function () {
        question.mouseoverdetails = true;
      };
    };

    $scope.back = () => {
      $location.path('/report-menu');
    };

    $scope.backgroundColor = (even, index, q) => {
      return !even ? { 'background-color': '#F5F5F5' } : {};
    };

    $scope.downloadPDF = () => {
      const isIE11 = window.ActiveXObject || 'ActiveXObject' in window;

      if (!isIE11) {
        Reports.togglePrintElements(true);

        $timeout(() => {
          Reports.downloadPDF({
            elementId: 'print-section',
            filename: $scope.headerTitle
          }).then((d) => {
            Reports.togglePrintElements(false);
          });
        });
      } else {
        // IE11
        modalInstance = vskoModalService.open({
          size: 'medium',
          template: require('../../message/IE11-notSupported.html'),
          scope: $scope
        });
      }
    };

    $scope.onCancel = () => {
      // close modal
      modalInstance.dismiss('cancel');
    };

    /** Local functions * */

    /**
     * Group questions by tip (only add those questions that should be showed -> more than 30% negative)
     */
    function groupQuestionsByTip() {
      $scope.tipTypes = [{
        name: 'Tips op niveau van de organisatie', tips: [] // eg. tip = {description: '', questions: []}
      }, {
        name: 'Tips op niveau van de individuen', tips: []
      }];

      $scope.components.forEach(component => {
        component.questions.forEach(q => {
          if ($scope.showQuestion(q)) {
            if (q.tipsOrganisation.length > 0) {
              q.tipsOrganisation.forEach(qTip => {
                let tip = findTip(qTip, $scope.tipTypes[0].tips);
                if (tip) {
                  tip.questions.push(q);
                } else {
                  $scope.tipTypes[0].tips.push({ description: qTip, questions: [q] });
                }
              });
            }

            if (q.tipsIndividuals.length > 0) {
              q.tipsIndividuals.forEach(qTip => {
                let tip = findTip(qTip, $scope.tipTypes[1].tips);
                if (tip) {
                  tip.questions.push(q);
                } else {
                  $scope.tipTypes[1].tips.push({ description: qTip, questions: [q] });
                }
              });
            }
          }
        });
      });
    }

    /**
     * Sort the questions inside each tip by negative percentage desc
     */
    function sortTipsQuestions() {
      pageNr = 1;
      questionsCount = 0;


      $scope.tipTypes.forEach(type => {
        questionsCount++;

        // sort also the tips by name
        type.tips = type.tips.sort((t1, t2) => {
          return t1.description.localeCompare(t2.description);
        });

        type.tips.forEach(tip => {
          let count = 0;
          questionsCount++;

          // sort questions by biggest sum of negative percentage and filter those questions will at least 30% negative
          tip.questions = Utils.sortQuestionsNegativeTop(tip.questions)
            .filter(q => $scope.showQuestion(q))
            .map(addPageBreaks);
        });
      });

      console.log('Tips types:', $scope.tipTypes);

      // last question of last component also has a break to show the pagination
      let lastTipQuestions = $scope.tipTypes[1].tips[$scope.tipTypes[1].tips.length - 1].questions;
      lastTipQuestions[lastTipQuestions.length - 1].pageBreak = { pageNr: pageNr, lastPage: true };

      $scope.totalPages = pageNr;
    }

    let pageNr = 1;
    let questionsCount = 0;

    function addPageBreaks(q) {
      questionsCount++;

      if (pageBreakConfig
            && ((questionsCount >= pageBreakConfig.firstPageQuestions && pageNr === 1)
              || questionsCount >= pageBreakConfig.otherPagesQuestions)) {
        console.log('Page break:', questionsCount, pageNr, q);

        q.pageBreak = { pageNr: pageNr };
        pageNr++;
        questionsCount = 0;
      } else {
        delete q.pageBreak;
      }

      return {
        key: q.key,
        description: q.description,
        sections: q.sections,
        info: q.info,
        pageBreak: q.pageBreak
      };
    }

    function findTip(tipDescription, tipsList) {
      let tipObj;
      tipsList.forEach(t => {
        if (t.description === tipDescription) {
          tipObj = t;
        }
      });
      return tipObj;
    }
  }];
