function isSameScope(parentElemScope, componentScope, eventScope) {
  'use strict';

  if (componentScope === eventScope) {
    return true;
  }

  if (parentElemScope === componentScope) {
    return false;
  }

  return isSameScope(parentElemScope, componentScope, eventScope.$parent);
}

module.exports = [function () {
  'use strict';

  return {
    restrict: 'A',
    scope: false,
    link: function (scope, element, attrs) {

      let events = scope.$eval(attrs.onEvent),
        componentScope = angular.element(element[0].childNodes[0]).scope();

      if (Array.isArray(events)) {

        if (!Array.isArray(events[0])) {
          events = [events];
        }

        events.forEach((event) => {

          let eventName = event[0],
            eventHandler = event[1],
            enablePropagation = event[2];

          if (typeof eventHandler === 'string') {
            eventHandler = function (e, data) {
              scope[eventHandler] = data;
            };
          }

          scope.$on(eventName, (e, arg) => {

            if (!enablePropagation) {e.stopPropagation();}

            // if (isSameScope(scope, componentScope, e.targetScope)) {
              if (typeof eventHandler === 'function') { eventHandler(scope, e, arg); }
              else { console.error('eventHandler is not a function for event: ' + eventName); }
            // }

          });

        });

      } else {
        console.error('You are not using onEvent correctly.');
      }

    }
  };
}];
