module.exports = [ '$scope', 'settings', 'constants', '$location', 'Survey', 'vskoModalService', 'Utils',
  function ($scope, settings, constants, $location, Survey, vskoModalService, Utils) { //eslint-disable-line
    'use strict';

    let modalInstance;

    $scope.stepNumber = 3;
    $scope.$emit('wellbe_wizard_step', {stepNumber: $scope.stepNumber});

    $scope.headerTitle = 'Groepen';
    $scope.headerDescription = 'Je kan het team indelen in groepen. Na de bevraging kan je hun resultaten apart bekijken. Om anonimiteit te garanderen moet elke groep weliswaar minstens 6 personen omvatten. We stellen standaard 3 groepen voor. Je kan dit wijzigen.';

    $scope.groupTypes = constants.groupTypes;

    // init
    Survey.getSurvey().then(survey => {
      
      if (!survey) {
        // shouldn't happen
        $location.path('/welcome');
        return;
      }

      if (!survey.groups) {
        // init with 3 default groups
        survey.groups = constants.defaultGroups.map(group => {
          group.key = Utils.getUUID();
          return group;
        });  
      }

      $scope.survey = survey;
    });

    $scope.onNext = function() {

      try {
        if ($scope.survey.groups.length > 0) {
          Survey.saveSurvey($scope.survey)
            .then(() => $location.path('/participants'));
        } else {
          $scope.feedbackMessage = 'Je moet minstens 1 groep hebben.';
        }
      } catch(ex) {
        // TODO check ex type
        console.log(ex);
      }
    }

    $scope.removeGroup = function(scope, e, group) {
      $scope.survey.groups = $scope.survey.groups.filter(gr => gr.key !== group.key);
    }

    $scope.open = (scope, e, group) => {
      // open with selected group or create a new one
      $scope.group = group ? group 
                           : { key: Utils.getUUID(), 
                               type: constants.groupTypes[0].value,
                               isNew: true };

      modalInstance = vskoModalService.open({
        size: 'medium',
        template: require('./groupDetail.html'),
        scope: $scope
      });
    }

    $scope.saveGroup = (group) => {
      if (group.isNew) {
        $scope.survey.groups.push(group);
        delete group.isNew;
      }
      modalInstance.dismiss('cancel');
    }

    $scope.onCancel = () => {
      // close modal
      modalInstance.dismiss('cancel');
    }

    $scope.back = () => {
      $location.path('/survey-selection');
    }
}];