
class appRoot {

    constructor($rootScope, $location, settings, Survey) {

      this.profileUrl = settings.mijnMe;
      this.$rootScope = $rootScope;

      $rootScope.manualHref = '/manuals/handleiding.pdf';

      this.steps = [{number: 1, text: '1', title: 'Intake'}, 
                    {number: 2, text: '2', title: 'Einddatum'}, 
                    {number: 3, text: '3', title: 'Groepen'}, 
                    {number: 4, text: '4', title: 'Deelnemers'}, 
                    {number: 5, text: '5', title: 'Uitnodiging'}, 
                    {number: 6, title: 'Overzicht', img: 'overview-grey-lighter.svg', style: {width: '25px', position: 'relative', top: '-2px'}}                  
                  ];

      if (!Survey.isSchoolLoaded()) {
        $location.path('/welcome');
      }
    }

    // event handlers
    // ['wellbe_wizard_visibility', 'wellbe_wizard_step', 'wellbe_header_logoVisibility']

    wizardVisibility(scope, e, arg) {
      scope.ctrl.wizardVisible = arg.visible;
    }

    wizardStep(scope, e, arg) {
      console.log('Event catch, change step:',scope, e,arg);
      scope.ctrl.wizardVisible = true;
      scope.ctrl.wizardStep = arg.stepNumber;
    }

    headerLogoVisibility(scope, e, arg) {
      scope.ctrl.headerLogoVisible = arg.visible;
    }

    userLoaded(scope, e, arg) {
      scope.ctrl.$rootScope.user = arg.user;
    }
  
    // injection here
    static get $inject() {
        return [
          '$rootScope',
          '$location',
          'settings',
          'Survey'  
        ];
      }
  
  }
  
module.exports = {
    template: require('./appRoot.html'),
    controllerAs: 'ctrl',
    controller: appRoot
};
  