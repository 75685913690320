module.exports = ['$scope', '$rootScope', '$translate', '$location', 'Survey', 'Utils', 'Organisations',
  function ($scope, $rootScope, $translate, $location, Survey, Utils, Organisations) { //eslint-disable-line

    'use strict';

    $scope.stepNumber = 6;
    $scope.$emit('wellbe_wizard_step', { stepNumber: $scope.stepNumber });

    $scope.headerTitle = 'Overzicht';
    $scope.headerDescription = 'Lees je gegevens na, en druk op Verzenden als je klaar bent.';

    // init
    Survey.getSurvey().then(survey => {
      if (survey.emailtemplate && survey.emailtemplate.body) {
        $scope.mailBody = survey.emailtemplate.body.replace('{{link}}', '<a href="/#/overview">wellbe</a>');
        $scope.mailBody = survey.emailtemplate.body.replace('{{username}}', $rootScope.user.username);
        $scope.mailBody = $scope.mailBody.replace('...</p>', '');
        $scope.mailBody = $scope.mailBody.replace('...<p></p>', '');
        $scope.mailBody = $scope.mailBody.replace('... <p></p>', '');
      }

      $scope.formattedEnddate = Utils.toString(new Date(survey.enddate));

      // sort participants in groups vertically
      survey.groups.forEach(group => {
        group.participants = group.participants.sort((p1, p2) => p1.email > p2.email ? 1 : -1);
        group.lines = Utils.splitArray(group.participants, group.participants.length > 8 ? 3 : 2);

        group.participants.forEach(participant => {
          participant.email = participant.name ? participant.name : participant.email;
          participant.shortEmail = participant.email.length > 37 ? participant.email.substr(0, 35) + '...' : participant.email;
        });
      });

      $scope.survey = survey;
    });

    $scope.onNext = async () => {
      $scope.survey.finishedon = new Date().toISOString();


      // TODO remove: showing temporarely popup with survey url
      $scope.surveyLink = $location.protocol() + '://' + $location.host() + ($location.port() ? (':' + $location.port()) : '')
        + '/survey/#/survey/' + $scope.survey.key
        + '?code=' + $scope.survey.groups[0].participants[0].key + '&school=' + $scope.survey.school.href;

      /* const modalInstance = vskoModalService.open({
        template: require('./surveyLink.html'),
        scope: $scope
      }); */

      // send mail to all the participants
      let allParticipants = [];
      $scope.survey.groups.forEach(group => {
        group.participants.forEach(participant => {
          allParticipants.push(participant);
        });
      });

      // allParticipants = allParticipants.slice(0,1); // TODO remove!, just to send 1 email

      try {
        await Survey.sendParticipantsMail(allParticipants, $scope.survey.emailtemplate, $scope.survey);

        await Survey.saveSurvey($scope.survey);

        const paEmails = await Organisations.getAllPreventieAdvisorMails(Survey.getSchool(), $rootScope.user);
        Survey.sendPreventieAdvisorMail('NEW_SURVEY_SENT', paEmails, $scope.survey);

        $location.path('/status');
      } catch (err) {
        let errmsg = err.message
          ? err.message
          : err.body.errors.map(e => e.message).join(', ');
        if (!errmsg) {
          errmsg = $translate.instant('error.general.mails');
        }

        $scope.feedbackMessage = {
          text: errmsg + ' (' + err.body.status + ')' // 'Mail sent to pending participants.'
        };
      }
    };

    // TODO remove when the survey link popup is not needed anymore
    $scope.onClose = () => {
      $location.path('/status');
    };

    $scope.back = () => {
      $location.path('/survey-selection');
    };
  }];
