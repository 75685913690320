module.exports = [ '$scope', '$q', '$location', 'Survey', 'sriClient', 'Utils', 'Organisations',
  async function ($scope, $q, $location, Survey, sriClient, Utils, Organisations) { //eslint-disable-line
    'use strict';

    $scope.$emit('wellbe_wizard_visibility', {visible: false});
    $scope.$emit('wellbe_header_logoVisibility', {visible: true});

    $scope.selectedDirectors = [];

    console.log('Directors - selected school:', Survey.getSchool());

    
    $scope.onChooseDirector = (director) => {
      director.selected = !director.selected;
    }
    
    $scope.onNext = () => {
      
      const selectedDirectors = $scope.directors.filter(d => d.selected)

      if (selectedDirectors.length > 0 || $scope.directors.length === 0) {

        Survey.setDirectors(selectedDirectors);
        $location.path('/intake');

      } else {
        // show error message
        $scope.feedbackMessage = 'Je moet minstens 1 directeur aanduiden.';
      }
      
    }

    $scope.directors = await Organisations.getDirectors(Survey.getSchool());

    if ($scope.directors.length <= 1) { // #15575
      Survey.setDirectors($scope.directors);
      $location.path('/intake');
    }
  }];
