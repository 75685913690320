module.exports = ['$scope', 'settings', 'constants', 'vskoModalService', '$location', '$timeout', 'Utils', 'dateUtils', 'Survey', 'Reports', 'XlsxReports',
  async function ($scope, settings, constants, vskoModalService, $location, $timeout, Utils, dateUtils, Survey, Reports, XlsxReports) { //eslint-disable-line

    let modalInstance;

    $scope.headerTitle = 'Dashboard Beleid';
    $scope.headerDescription = '';

    $scope.overall = {};

    $scope.situations = constants.reportSituations;

    $scope.filter = {
      group: 'ALL',
      situation: $scope.situations[0].key,
      compare: 'NONE',
      options: {}
    };

    $scope.helpTooltip = 'Download de ruwe data van deze bevraging, en vergelijk ze met andere bevragingen in je bestuur, je referentiegroep en alle Vlaamse Katholieke instellingen.';

    const pageBreakConfig = {
      firstPageQuestions: 16,
      otherPagesQuestions: 22
    };

    let cleanSurveyParticipantsInfo;
    let surveyParticipantsInfo;

    // init
    Survey.getSurvey().then(survey => {
      // get surveys to compare with
      // . surveys available for the current user, past year and other schools
      // . surveys already ended
      // . surveys with at least 5 answers (#15654)
      $scope.surveyComparations = Survey.getCachedUserSurveys()
        .filter(s => s.key !== survey.key
                && s.enddate && dateUtils.isAfter(dateUtils.stripTime(new Date().toISOString()), dateUtils.stripTime(s.enddate)) // only compare with ended surveys (#15552)
                && s.answers && s.answers.length >= constants.minAnswersForComparation)
        .map(s => {
          const title = s.formattedEnddate + ' - ' + s.school.$$expanded.$$displayName + ', ' + Utils.getLocationAddresses(s, s.school.$$expanded);
          let participantsCount = 0;
          s.groups.forEach(group => participantsCount += group.participants.length);

          return {
            key: s.key,
            title: title.length < 75 ? title : title.slice(0, 75) + '...',
            participantsCount: participantsCount
          };
        });

      // note: should be done before getting the answers
      $scope.components = Reports.generateComponentsStructure();

      $scope.formattedEnddate = Utils.toString(new Date(survey.enddate));

      $scope.survey = survey;

      Survey.getAnswers(survey.key, null, false).then(answers => {
        $scope.answers = answers;
        console.log('Survey answers:', answers);

        // info to be displayed in the top right corner
        $scope.countParticipants = 0;
        survey.groups.forEach(group => $scope.countParticipants += group.participants.length);
        cleanSurveyParticipantsInfo = 'Aantal deelnemers: ' + answers.length + '/' + $scope.countParticipants;
        $scope.participantsInfo = cleanSurveyParticipantsInfo;

        Reports.generatePercentageBoxes($scope.answers, $scope.components, $scope.overall,
          { situation: $scope.filter.situation, debug: true });

        sortComponentsQuestions(pageBreakConfig);

        // rule: filter groups with at least 5 participants with answers (#15165)
        $scope.filter.options.groups = $scope.survey.groups.filter(group => {
          const answersInGroup = $scope.answers.filter(answer => answer.group === group.key).length;
          return answersInGroup >= 5;
        });
      });
    });

    /* Filter actions */

    $scope.updatedGroupFilter = () => {
      // get the group type from the survey
      $scope.selectedGroup = $scope.filter.group !== 'ALL' ? $scope.survey.groups.find(g => g.key === $scope.filter.group) : null;
      $scope.filter.groupType = $scope.selectedGroup ? $scope.selectedGroup.type : null;

      // recreate the components structure showing only questions of the selected group
      $scope.components = Reports.generateComponentsStructure($scope.filter.groupType);

      $scope.groupAnswers = Utils.filterAnswersByGroup($scope.answers, $scope.filter.group);
      Reports.generatePercentageBoxes($scope.groupAnswers, $scope.components, $scope.overall, { situation: $scope.filter.situation });

      sortComponentsQuestions();

      // participants info to show in top right corner, only those of the selected group
      if ($scope.filter.group !== 'ALL') {
        $scope.participantsInfo = 'Aantal deelnemers: ' + $scope.groupAnswers.length + '/' + $scope.selectedGroup.participants.length;
      } else {
        $scope.participantsInfo = surveyParticipantsInfo;
      }

      if ($scope.filter.compare !== 'NONE') {
        // refresh also the compare survey percentages
        Reports.generatePercentageBoxes(Utils.filterAnswersByGroup($scope.filter.selectedCompareSurvey.answers, $scope.filter.group), $scope.components, $scope.overall, { situation: $scope.filter.situation, compareSections: true, skipInfo: true });
      }
    };

    $scope.updatedCompareWith = () => {
      // clear compare sections
      $scope.overall.compareSections = [];

      $scope.components.forEach(c => {
        c.compareSections = [];
        c.questions.forEach(q => {
          q.compareSections = [];
        });
      });

      if ($scope.filter.compare === 'NONE') {
        $scope.overall.compareSections = [];

        $scope.participantsInfo = surveyParticipantsInfo = cleanSurveyParticipantsInfo;
      } else {
        // generate percentage boxes for the compare survey
        $scope.filter.selectedCompareSurvey = $scope.surveyComparations.filter(s => s.key === $scope.filter.compare)[0];

        Survey.getAnswers($scope.filter.selectedCompareSurvey.key, null, false).then(answers => {
          $scope.filter.selectedCompareSurvey.answers = answers;
          // console.log('Compared survey answers:', answers)

          Reports.generatePercentageBoxes(Utils.filterAnswersByGroup(answers, $scope.filter.group), $scope.components, $scope.overall, { situation: $scope.filter.situation, compareSections: true, skipInfo: true });

          surveyParticipantsInfo = cleanSurveyParticipantsInfo + '<span class="compare-participants-info">' + '  (vergelijken ' + answers.length + '/' + $scope.filter.selectedCompareSurvey.participantsCount + ')</span>';
          $scope.participantsInfo = surveyParticipantsInfo;
        });
      }
    };

    $scope.updatedSituationFilter = () => {
      console.log('Situation filter:', $scope.filter.situation);

      // recreate the components structure showing only questions of the selected group
      $scope.components = Reports.generateComponentsStructure($scope.filter.groupType);

      Reports.generatePercentageBoxes(Utils.filterAnswersByGroup($scope.answers, $scope.filter.group), $scope.components, $scope.overall, { situation: $scope.filter.situation });

      sortComponentsQuestions();

      if ($scope.filter.compare !== 'NONE') {
        // refresh also the compare survey percentages
        Reports.generatePercentageBoxes(Utils.filterAnswersByGroup($scope.filter.selectedCompareSurvey.answers, $scope.filter.group), $scope.components, $scope.overall, { situation: $scope.filter.situation, compareSections: true });
      }
    };

    // remove mouseover of other components, questions and overall
    $scope.overComponent = (component) => {
      component.mouseover = function () {
        component.mouseoverdetails = true;
      };

      $scope.components.forEach(c => {
        if (c.title != component.title) {
          delete c.mouseover;
          delete c.mouseoverdetails;
        }

        c.questions.forEach(q => {
          delete q.mouseover;
          delete q.mouseoverdetails;
        });
      });

      delete $scope.overall.mouseover;
      delete $scope.overall.mouseoverdetails;
    };

    // remove mouseover of other components, questions and overall
    $scope.overQuestion = (question) => {
      question.mouseover = function () {
        question.mouseoverdetails = true;
      };

      $scope.components.forEach(c => {
        delete c.mouseover;
        delete c.mouseoverdetails;

        c.questions.forEach(q => {
          if (q.key !== question.key) {
            delete q.mouseover;
            delete q.mouseoverdetails;
          }
        });
      });

      delete $scope.overall.mouseover;
      delete $scope.overall.mouseoverdetails;
    };

    // mouseover overall report box, remove flag from other components/qst
    $scope.overOverall = () => {
      $scope.overall.mouseover = function () {
        $scope.overall.mouseoverdetails = true;
      };

      $scope.components.forEach(c => {
        delete c.mouseover;
        delete c.mouseoverdetails;

        c.questions.forEach(q => {
          delete q.mouseover;
          delete q.mouseoverdetails;
        });
      });
    };

    $scope.back = () => {
      $location.path('/report-menu');
    };

    $scope.backgroundColor = (even, index, q) => {
      return !q.even ? { 'background-color': '#F5F5F5' } : {};
    };

    $scope.downloadPDF = () => {
      const isIE11 = window.ActiveXObject || 'ActiveXObject' in window;

      if (!isIE11) {
        $scope.downloadingPdf = true;
        Reports.togglePrintElements(true);

        $timeout(() => {
          Reports.downloadPDF({
            elementId: 'print-section',
            filename: $scope.headerTitle
          }).then((d) => {
            Reports.togglePrintElements(false);
            $scope.downloadingPdf = false;
          });
        });
      } else {
        // IE11
        modalInstance = vskoModalService.open({
          size: 'medium',
          template: require('../message/IE11-notSupported.html'),
          scope: $scope
        });
      }
    };

    $scope.downloadXLSX = async () => {
      const isIE11 = window.ActiveXObject || 'ActiveXObject' in window;

      if (!isIE11) {
        $scope.downloadingXlsx = true;

        const wsName = 'Dashboard Beleid';
        const filename = wsName + '.xlsx';

        const allSurveysData = await Survey.getAllSurveysAndAnswers();

        const countParticipants = $scope.selectedGroup
          ? $scope.selectedGroup.participants.length : $scope.countParticipants;

        const totalAnswers = $scope.selectedGroup
          ? $scope.groupAnswers.length : $scope.answers.length;

        console.log('Generating xlsx report', new Date());

        const data = await XlsxReports.getReportData(
          $scope.survey,
          totalAnswers,
          countParticipants,
          null,
          $scope.components,
          allSurveysData,
          wsName,
          $scope.survey.groups.find(g => g.key === $scope.filter.group),
          $scope.filter.situation
        );

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(data.xlsxData);

        XlsxReports.addStyles(ws, Object.keys(data.surveyGroupsToInclude));

        /* add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, wsName);

        /* write workbook */
        // xlsxStyles.writeFile(wb, filename);
        XlsxReports.write(wb, filename);

        console.log('Done write', new Date());
        $scope.downloadingXlsx = false;
      } else {
        // IE11
        modalInstance = vskoModalService.open({
          size: 'medium',
          template: require('../message/IE11-notSupported.html'),
          scope: $scope
        });
      }
    };

    $scope.onCancel = () => {
      // close modal
      modalInstance.dismiss('cancel');
    };

    /** Local functions * */

    /**
     * Filter out those questions without answers in each component (#16981)
     *
     * @param {*} components
     * @returns components
     */
    function filterNotAnsweredQuestions(components) {
      return components.map(c => {
        c.questions = c.questions.filter(q => q.answers.length);
        return c;
      });
    }

    function sortComponentsQuestions(pageBreakConfig) {
      pageNr = 1;
      questionsCount = 0;

      $scope.components = filterNotAnsweredQuestions($scope.components);
      $scope.components.forEach(component => {
        let count = 0;
        questionsCount += 2; // the space of a compoent is equals to 2 questions

        // sort questions by biggest sum of negative percentage
        component.questions = Utils.sortQuestionsNegativeTop(component.questions)
          .map(q => {
            q.even = count % 2 === 0;
            count++;
            return q;
          })
          .map(addPageBreaks);
      });

      // last question of last component also has a break to show the pagination
      const lastComponentQuestions = $scope.components[$scope.components.length - 1].questions;
      lastComponentQuestions[lastComponentQuestions.length - 1].pageBreak = { pageNr: pageNr, lastPage: true };

      $scope.totalPages = pageNr;
    }

    let pageNr = 1;
    let questionsCount = 0;

    function addPageBreaks(q, i) {
      questionsCount++;

      if (pageBreakConfig
            && ((questionsCount >= pageBreakConfig.firstPageQuestions && pageNr === 1)
              || questionsCount >= pageBreakConfig.otherPagesQuestions)) {
        console.log('Page break:', questionsCount, pageNr, i, q);

        q.pageBreak = { pageNr: pageNr };
        pageNr++;
        questionsCount = 0;
      } else {
        delete q.pageBreak;
      }

      q.count = questionsCount;

      return q;
    }
  }];
