/* eslint-disable max-len */
const organisations = require('../../services/organisations');

module.exports = ['$scope', '$rootScope', 'constants', '$location', '$translate', 'Survey', 'Utils', 'Organisations',
  function ($scope, $rootScope, constants, $location, $translate, Survey, Utils, Organisations) { //eslint-disable-line

    'use strict';

    $scope.$emit('wellbe_wizard_visibility', { visible: false });

    $scope.data = {
      percentage: 0
    };

    $scope.newMembers = [];

    // init
    Survey.getSurvey().then(survey => {
      updateEnddateWarning(survey);

      $scope.survey = survey;

      // calculate answered percentage
      updatePercentage(survey);

      // check participants bounced emails
      updateBouncedEmails(survey);

      let school = Survey.getSchool();
      $scope.schoolName = school.name;
      $scope.schoolAddress = school.address;
      $scope.locationAddress = survey.location ? survey.location.display : school.locations.map(l => l.display).join(' - ');
    });

    $scope.addMemberMail = async () => {
      let activeGroup = $scope.survey.groups[$scope.data.activeGroupIdx];
      let newParticipant;

      delete $scope.addMemberFeedback;

      if ($scope.data.newMemberMail) {
        $scope.data.newMemberMail = $scope.data.newMemberMail.trim();

        let valid = Survey.validEmail($scope.data.newMemberMail);
        if (!valid) {
          $scope.addMemberFeedback = 'Foutief e-mail adres.'; // 'Invalid mail.'
        } else if (!Utils.surveyContainsEmail($scope.survey, $scope.data.newMemberMail)) {
          newParticipant = {
            key: Utils.getUUID(),
            email: $scope.data.newMemberMail,
            name: $scope.data.newMemberMail
          };

          activeGroup.participants.push(newParticipant);

          $scope.newMembers.push(newParticipant);

          updatePercentage($scope.survey);

          $scope.data.newMemberMail = '';

          Survey.saveSurvey($scope.survey).then(() => {
            newParticipant.isNew = true;
          });
        } else {
          $scope.addMemberFeedback = 'Dit e-mail adres is reeds in deze groep van deze bevraging.'; // 'Mail already in the group.'
        }
      }
    };

    $scope.removeParticipant = (scope, e, member) => {
      let activeGroup = $scope.survey.groups[$scope.data.activeGroupIdx];

      // can't leave a group with less than 6 participants
      if (activeGroup.participants.length - 1 >= constants.minGroupParticipants) {
        activeGroup.participants = activeGroup.participants
          .filter(participant => participant.key !== member.key);

        $scope.newMembers = $scope.newMembers.filter(participant => participant.key !== member.key);

        updatePercentage($scope.survey);

        Survey.saveSurvey($scope.survey);

        // TODO: also delete the /answers of the participant being removed

        delete $scope.participantsFeedback;
      } else {
        $scope.participantsFeedback = 'A group can\'t have less than ' + constants.minGroupParticipants + ' participants. (TODO:translate)';
      }
    };

    $scope.newMemberKeypressed = (ev) => {
      if (ev.keyCode === 13) {
        $scope.addMemberMail();
      }
    };

    $scope.updateEndDate = () => {
      let d = (new Date($scope.survey.enddate + 'T12:00:00.000Z'));
      d.setHours(12);
      $scope.survey.enddate = d.toISOString();

      // update end date warning
      updateEnddateWarning($scope.survey);

      Survey.saveSurvey($scope.survey);
    };

    $scope.enddateWarningClass = () => {
      return $scope.data.enddateWarning ? 'warn' : '';
    };

    // send reminder mails to all participants that didnt answer the survey yet
    $scope.sendMailMissingAnswered = () => {
      let missingAnswerParticipants = [];
      let codesAnswered = [];

      $scope.survey.groups.forEach(group => {
        group.participants.forEach(participant => {
          if (!participant.submitted) {
            missingAnswerParticipants.push(participant);
          }
        });
      });

      // include also the new members just added (then remove from that list)
      $scope.newMembers.forEach(member => missingAnswerParticipants.push(member));

      console.log('Missing participants to answer count:', missingAnswerParticipants.length);

      if (missingAnswerParticipants.length > 0) {
        Survey.sendParticipantsMail(missingAnswerParticipants, $scope.survey.emailtemplate, $scope.survey)
          .then(async () => {
            const paEmails = await Organisations.getAllPreventieAdvisorMails(Survey.getSchool(), $rootScope.user);
            Survey.sendPreventieAdvisorMail('REMINDERS_SENT', paEmails, $scope.survey);

            $scope.newMembers = [];

            $scope.feedbackMessage = {
              text: 'Er is een herinnering verstuurd.', // 'Mail sent to pending participants.'
              class: 'success-message'
            };
          }, (err) => {
            let msg = err.message
              ? err.message.replace('uitnodiging', 'herinnering')
              : 'Er is een probleem bij het versturen van de herinnering. Gelieve dit probleem te melden aan WellBe@katholiekonderwijs.vlaanderen';
            if (err.body && err.body.errors.find(e => e.code === 'duplicate.recipient')) {
              msg = 'Dit e-mail adres is reeds in deze groep van deze bevraging.';
              console.error('There are duplicate participants in the list: ' + err.body.errors.find(e => e.code === 'duplicate.recipient').duplicates);
            }
            $scope.feedbackMessage = {
              text: msg
            };
          });
      } else {
        $scope.feedbackMessage = {
          text: 'Alle bevragingen werden reeds ingevuld. Het is onmogelijk om een herinnering te sturen.'
        };
      }
    };

    $scope.sendMailNewParticipants = () => {
      console.log('New participants mails:', $scope.newMembers.length);

      if ($scope.newMembers.length > 0) {
        Survey.sendParticipantsMail($scope.newMembers, $scope.survey.emailtemplate, $scope.survey)
          .then(async () => {
            const paEmails = await Organisations.getAllPreventieAdvisorMails(Survey.getSchool(), $rootScope.user);
            Survey.sendPreventieAdvisorMail('NEW_EMAILS_SENT', paEmails, $scope.survey);

            $scope.newMembers.forEach(p => {
              delete p.isNew;
            });
            $scope.newMembers = [];

            $scope.feedbackMessage = {
              text: 'E-mail is verzonden naar nieuwe deelnemers.', // 'Mail sent to new participants.',
              class: 'success-message'
            };
          }, (err) => {
            $scope.feedbackMessage = {
              text: err.message
            };
          });
      }
    };

    $scope.emailEdited = async (participant) => {
      $scope.confirmEditEmailFeedback = {};
      participant.email = participant.email.trim();

      if (!Survey.validEmail(participant.email)) {
        $scope.confirmEditEmailFeedback[participant.key] = 'Foutief e-mail adres.'; // 'Invalid mail.'
        return;
      }

      let emailExists;
      $scope.survey.groups.forEach(group => {
        group.participants.forEach(groupParticipant => {
          if (groupParticipant.key !== participant.key
            && participant.email.toUpperCase() === groupParticipant.email.toUpperCase()
          ) {
            emailExists = true;
          }
        });
      });
      if (emailExists) {
        $scope.confirmEditEmailFeedback[participant.key] = 'Dit e-mail adres is reeds in deze groep van deze bevraging.'; // 'Mail already in the group.'
        return;
      }

      try {
        // send mail to participant edited mail (same template as new email), must have the externalReference to /surveys
        await Survey.sendParticipantsMail([participant], $scope.survey.emailtemplate, $scope.survey);

        delete participant.bouncedEmail;
        delete participant.editingEmail;

        // save survey with the edited particpant mail
        await Survey.saveSurvey($scope.survey);

        const paEmails = await Organisations.getAllPreventieAdvisorMails(Survey.getSchool(), $rootScope.user);
        Survey.sendPreventieAdvisorMail('EDITED_PARTICIPANT_EMAIL', paEmails, $scope.survey, participant);

        $scope.feedbackMessage = {
          text: 'Er is een email gestuurd naar dit gewijzigde emailadres van deze deelnemer.', // 'Mail sent to edited participant.',
          class: 'success-message'
        };
      } catch (err) {
        let errmsg = err.message
          ? err.message
          : err.body.errors.map(e => e.message).join(', ');
        if (!errmsg) {
          errmsg = $translate.instant('error.general.mails');
        }

        $scope.feedbackMessage = {
          text: errmsg + ' (' + err.body.status + ')'
        };
      }
    };

    $scope.back = () => {
      $location.path('/survey-selection');
    };


    function updateEnddateWarning(survey) {
      let enddate = (new Date(survey.enddate));
      // enddate.setHours(12);

      let current = (new Date());
      current.setHours(12);

      let daysDiff = daysBetween(current, enddate) + 1;

      console.log('Days between:', daysDiff);

      if (daysDiff == 1) {
        $scope.data.enddateWarning = 'De laatste dag dat de bevraging openstaat.';
      } else if (daysDiff <= 7) {
        $scope.data.enddateWarning = 'De bevraging zal nog ' + daysDiff + ' dagen openstaan.';
      } else {
        delete $scope.data.enddateWarning;
      }
    }

    function daysBetween(date1, date2) {
      // Get 1 day in milliseconds
      var one_day = 1000 * 60 * 60 * 24;

      // Convert both dates to milliseconds
      var date1_ms = date1.getTime();
      var date2_ms = date2.getTime();

      // Calculate the difference in milliseconds
      var difference_ms = date2_ms - date1_ms;

      // Convert back to days and return
      return Math.round(difference_ms / one_day);
    }

    function updatePercentage(survey) {
      // TODO calculate participants that already answered
      survey.participantsAnswered = Utils.countParticipantsAnswered(survey);
      survey.totalParticipants = 0;
      survey.groups.forEach(group => {
        survey.totalParticipants += group.participants.length;
      });

      $scope.data.percentage = Math.round(survey.participantsAnswered * 100 / survey.totalParticipants);
    }

    function updateBouncedEmails(survey) {
      Survey.getBouncedEmails(survey).then(bouncedEmails => {
        console.log(bouncedEmails);

        survey.groups.forEach(group => {
          group.participants.forEach(participant => {
            if (bouncedEmails.indexOf(participant.email) !== -1) {
              participant.bouncedEmail = true;
            }
          });
        });
      });
    }

    $scope.summernoteOptions = {
      height: 170,
      toolbar: [
        ['style', ['bold', 'italic', 'clear']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['link', ['linkDialogShow']]
      ]
    };
  }];
