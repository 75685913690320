module.exports = [ '$scope', 'settings', '$location', '$translate', 'constants', 'Survey', 'Utils',
  function ($scope, settings, $location, $translate, constants, Survey, Utils) { //eslint-disable-line
    'use strict';

    $scope.stepNumber = 4;
    $scope.$emit('wellbe_wizard_step', {stepNumber: $scope.stepNumber});

    $scope.headerTitle = 'Deelnemers';
    $scope.headerDescription = 'Voeg mensen toe door hun e-mail adres in te geven, of nodig een hele groep uit door een lijst van e-mail adressen te uploaden.';

    $scope.emptyGroupText = 'Er zijn geen personen geselecteerd, selecteer de juiste personen uit het schoolteam, en voeg ze toe aan deze lijst. Elke groep moet minstens 7 personen bevatten.';

    let minGroupParticipants = constants.minGroupParticipants;

    $scope.data = {
      newMemberMail: '',
      activeGroupIdx: 0
    }


    // init
    Survey.getSurvey().then(survey => {
      
      if (!survey) {
        // shouldn't happen
        $location.path('/welcome');
        return;
      }

      if (survey.groups && survey.groups.length > 0) {
        // load participants in each group, init as empty array if no members yet
        $scope.groups = survey.groups.map(group => {
          group.participants = group.participants || [];

          group.participants = group.participants.sort((p1, p2) => p1.email > p2.email ? 1 : -1);

          group.participants.forEach(participant => {
            participant.img = '/img/mail-grey.svg'; 
          });
          
          return group;
        });
      }

      $scope.survey = survey;
    });

    /**
     * Include the mail entered as a team member to be able to move it to some group.
     */
    $scope.addMemberMail = async () => {

      let activeGroup = $scope.groups[$scope.data.activeGroupIdx];

      delete $scope.addMemberFeedback;

      if ($scope.data.newMemberMail) {

        $scope.data.newMemberMail = $scope.data.newMemberMail.trim();

        let valid = Survey.validEmail($scope.data.newMemberMail);
        if (!valid) {
          $scope.addMemberFeedback = 'Foutief e-mail adres.'; // 'Invalid mail.'

        } else {

          if (!Utils.surveyContainsEmail($scope.survey, $scope.data.newMemberMail)) {
  
            activeGroup.participants.push({
              key: Utils.getUUID(),
              email: $scope.data.newMemberMail
            });
    
            $scope.data.newMemberMail = '';
    
            Survey.saveSurvey($scope.survey);
          } else {
            $scope.addMemberFeedback = 'Dit e-mail adres is reeds in deze groep van deze bevraging.'; // 'Mail already in the group.'
          }
        }
      }
    }

    /**
     * Remove the selected participant member from the right active group and 
     * include it in the left list of members.
     * Updates survey state in api.
     */
    $scope.removeParticipant = (scope, e, member) => {

      let activeGroup = $scope.groups[$scope.data.activeGroupIdx];

      activeGroup.participants = activeGroup.participants
      .map(participant => {
        if (participant.key === member.key) {
          // $scope.teamMembers.push(member);
          delete member.selected;
        }
        return participant;
      })
      .filter(participant => participant.key !== member.key);

      Survey.saveSurvey($scope.survey)
    }

    // callback from upload-particpants component
    $scope.loadedParticipantsFromExcel = (scope, e, sheet) => {

      console.log('Loaded participants from excel:', e, sheet);

      let activeGroup = $scope.groups[$scope.data.activeGroupIdx];
      let someExists, invalids = [];

      // include uploaded participants in active group 
      sheet.forEach(participant => {
        if (participant.email) {

          participant.email = participant.email.trim();

          let valid = Survey.validEmail(participant.email);
          
          if (!valid) {
            invalids.push(participant.email);

          } else {

            if (!Utils.surveyContainsEmail($scope.survey, participant.email)) {
              activeGroup.participants.push({key: Utils.getUUID(), 
                                            email: participant.email.trim(), 
                                            img: '/img/mail-grey.svg'});
            } else {
              someExists = true;
            }
          }

        }
      });

      if (someExists) {
        $scope.addMemberFeedback = 'Sommige e-mail adressen zitten reeds in een andere groep van deze bevraging';
      } 
      
      if (invalids.length > 0) {
        $scope.addMemberFeedback = 'Foutief e-mail adres: ' + invalids.join(',');
      } 
      
      if (!someExists && invalids.length === 0) {
        delete $scope.addMemberFeedback;
      }

      // sort list of participants after upload a bunch from excel
      activeGroup.participants = activeGroup.participants.sort((p1, p2) => p1.email > p2.email ? 1 : -1);

      // $scope.$apply(); // Need this to update angular of the changes

      // $scope.data.uploadingExcel = false;

      Survey.saveSurvey($scope.survey);
    }

    /**
     * On enter pressed we add the mail
     */
    $scope.newMemberKeypressed = (ev) => {
      // console.log('Keypress ev:',ev)

      if (ev.keyCode === 13) {
        $scope.addMemberMail();
      }
    }

    $scope.openUploadDialog = () => {
      console.log('Opened upload dialog')
      // $scope.data.uploadingExcel = true;
    }

    $scope.onNext = () => {
      // TODO need to save survey in the api or the partipants moves are already saved?
      // validate all groups have at least 7 participants
      let invalidGroups = $scope.groups.filter(group => group.participants.length < minGroupParticipants);
      if (invalidGroups.length === 0) {
        $location.path('/mail');
      } else {
        $scope.feedbackMessage = 'Niet alle groepen hebben ' + constants.minGroupParticipants + ' deelnemers.';
      }
    }

    $scope.back = () => {
      $location.path('/survey-selection');
    }
  }];
