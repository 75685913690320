'use strict'; //eslint-disable-line

require('../../css/index.js');

require('@uirouter/angularjs');
require('angular-sanitize');
require('angular-loading-bar');
require('angular-translate');
require('angular-translate-loader-static-files');
require('angular-ui-bootstrap');
require('angular-moment');
require('moment/locale/nl-be');
// require('angular-bind-notifier');
// require('vsko-datepicker');
require('vsko-notifications');
require('vsko-angular-oauth').client;
// require('vsko-resource-picker');

// require('../module/vsko-remote-error-logger');

// require('angular-summernote');
require('../module/angular-summernote/dist/angular-summernote');
require('../module/angular-summernote/lib/summernote');
require('../module/angular-summernote/lib/summernote.css');

require('@kathondvla/style');

// require('sri-client/ng-sri-client/dist/client');
require('@kathondvla/sri-client/ng-sri-client');

// require('../module/vsko-ux-patterns');
// require('../module/vsko-answers');

require('../module/vsko-datepicker');
require('../module/kathondvla-qti');

// require('../module/redactie-notification');
// require('../module/vsko-api-utils');
require('../module/ng-stats/ng-stats');

require('../services');

require('../../css/screens');
require('../../css/wellbe.css');

const kovSentry = require('./kovSentry');

var app = angular.module('wellbe', [
  'ng',
  'ngSanitize',
  'ui.router',
  'angular-loading-bar',
  'pascalprecht.translate',
  'summernote',
  'ui.bootstrap',
  'vsko.datepicker',
  'vsko.notifications',
  'VskoOAuth',
  'ng-sri-client',
  '@kathondvla/style',
  require('@kathondvla/ux-patterns'),
  require('../components'),
  require('../screens'),
  'kathondvla.qti',
  'angularStats',
  'angularMoment',
  'services'
]);

app.constant('kovSentry', kovSentry);

app.factory('params', [function () {
  // to be overwritten by uibModal or aside
  return {};
}]);

app.filter('trusted', ['$sce', function ($sce) {
  return function (url) {
    return $sce.trustAsResourceUrl(url);
  };
}]);

app.directive('onEvent', require('../directives/onEvent.js'));

module.exports = angular.module = app;
