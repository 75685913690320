module.exports = ['$scope', 'constants', '$location', '$timeout', 'vskoModalService', 'Utils', 'Survey', 'Reports', 'dateUtils',
  function ($scope, constants, $location, $timeout, vskoModalService, Utils, Survey, Reports, dateUtils) { //eslint-disable-line

    'use strict';

    let modalInstance;

    $scope.headerTitle = 'Dashboard Team';
    $scope.headerDescription = '';

    $scope.situations = constants.reportSituations;

    // TODO get real surveys to compare with (surveys available for the current user, past year and other schools)
    $scope.surveyComparations = [];

    $scope.filter = {
      group: 'ALL',
      situation: $scope.situations[0].key,
      compare: 'NONE',
      options: {}
    };

    let pageBreakConfig = {
      firstPageQuestions: 15,
      otherPagesQuestions: 21
    };

    let cleanSurveyParticipantsInfo;
    let surveyParticipantsInfo;

    // init
    Survey.getSurvey().then(survey => {
      // get surveys to compare with, only those:
      // . available for the current user, also past year
      // . same school
      // . already ended
      // . with at least 5 answers (#15654)
      // . with same location if selected in the current survey (#15577)
      $scope.surveyComparations = Survey.getCachedUserSurveys()
        .filter(s => s.key !== survey.key
                && s.school.href === survey.school.href // only compare with same school (#15560)
                && s.enddate && dateUtils.isAfter(dateUtils.stripTime(new Date().toISOString()), dateUtils.stripTime(s.enddate)) // only compare with ended surveys (#15552)
                && s.answers.length >= constants.minAnswersForComparation
                && (!survey.location || (s.location && s.location.href === survey.location.href)))
        .map(s => {
          let title = s.formattedEnddate + ' - ' + s.school.$$expanded.$$displayName + ', ' + Utils.getLocationAddresses(s, s.school.$$expanded);
          let participantsCount = 0;
          s.groups.forEach(group => participantsCount += group.participants.length);

          return {
            key: s.key,
            title: title.length < 75 ? title : title.slice(0, 75) + '...',
            participantsCount: participantsCount
          };
        });

      // note: should be done before getting the answers
      $scope.components = Reports.generateComponentsStructure();

      $scope.formattedEnddate = Utils.toString(new Date(survey.enddate));

      $scope.survey = survey;

      Survey.getAnswers(survey.key, null, false).then(answers => {
        $scope.answers = answers;
        console.log('Survey answers:', answers);

        // info to be displayed in the top right corner
        let countparticipants = 0;
        survey.groups.forEach(group => countparticipants += group.participants.length);
        cleanSurveyParticipantsInfo = 'Aantal deelnemers: ' + answers.length + '/' + countparticipants;
        $scope.participantsInfo = cleanSurveyParticipantsInfo;

        Reports.generatePercentageBoxes($scope.answers, $scope.components, null, { situation: $scope.filter.situation });

        $scope.questions = sortQuestions(joinQuestions(filterNotAnsweredQuestions($scope.components)));

        console.log('Dashboard team questions: ', $scope.questions);
        // $scope.questions = $scope.questions.slice(0,4)

        // rule: filter groups with at least 5 participants with answers (#15165)
        $scope.filter.options.groups = $scope.survey.groups.filter(group => {
          var answersInGroup = $scope.answers.filter(answer => answer.group === group.key).length;
          return answersInGroup >= 5;
        });
      });
    });

    /* Filter actions */

    $scope.updatedGroupFilter = () => {
      // get the group type from the survey
      const selectedGroup = $scope.filter.group !== 'ALL' ? $scope.survey.groups.find(g => g.key === $scope.filter.group) : null;
      $scope.filter.groupType = selectedGroup ? selectedGroup.type : null;

      // recreate the components structure showing only questions of the selected group
      $scope.components = Reports.generateComponentsStructure($scope.filter.groupType);

      const groupAnswers = Utils.filterAnswersByGroup($scope.answers, $scope.filter.group);
      Reports.generatePercentageBoxes(groupAnswers, $scope.components, null, { situation: $scope.filter.situation });

      $scope.questions = sortQuestions(joinQuestions(filterNotAnsweredQuestions($scope.components)));

      // participants info to show in top right corner, only those of the selected group
      if ($scope.filter.group !== 'ALL') {
        $scope.participantsInfo = 'Aantal deelnemers: ' + groupAnswers.length + '/' + selectedGroup.participants.length;
      } else {
        $scope.participantsInfo = surveyParticipantsInfo;
      }

      if ($scope.filter.compare !== 'NONE') {
        // refresh also the compare survey percentages
        Reports.generatePercentageBoxes(Utils.filterAnswersByGroup($scope.filter.selectedCompareSurvey.answers, $scope.filter.group), $scope.components, null, { situation: $scope.filter.situation, compareSections: true, skipInfo: true });
      }
    };

    $scope.updatedCompareWith = () => {
      // clear compare sections
      $scope.components.forEach(c => {
        c.compareSections = [];
        c.questions.forEach(q => {
          q.compareSections = [];
        });
      });

      if ($scope.filter.compare !== 'NONE') {
        // generate percentage boxes for the compare survey
        $scope.filter.selectedCompareSurvey = $scope.surveyComparations.filter(s => s.key === $scope.filter.compare)[0];

        Survey.getAnswers($scope.filter.selectedCompareSurvey.key, null, false).then(answers => {
          $scope.filter.selectedCompareSurvey.answers = answers;
          // console.log('Compared survey answers:', answers)

          Reports.generatePercentageBoxes(Utils.filterAnswersByGroup(answers, $scope.filter.group), $scope.components, $scope.overall, { situation: $scope.filter.situation, compareSections: true, skipInfo: true });

          surveyParticipantsInfo = cleanSurveyParticipantsInfo + '<span class="compare-participants-info">' + '  (vergelijken ' + answers.length + '/' + $scope.filter.selectedCompareSurvey.participantsCount + ')</span>';
          $scope.participantsInfo = surveyParticipantsInfo;
        });
      } else {
        $scope.participantsInfo = surveyParticipantsInfo = cleanSurveyParticipantsInfo;
      }
    };

    $scope.updatedSituationFilter = () => {
      console.log('Situation filter:', $scope.filter.situation);

      // recreate the components structure showing only questions of the selected group
      $scope.components = Reports.generateComponentsStructure($scope.filter.groupType);

      Reports.generatePercentageBoxes(Utils.filterAnswersByGroup($scope.answers, $scope.filter.group), $scope.components, null, { situation: $scope.filter.situation });

      $scope.questions = sortQuestions(joinQuestions(filterNotAnsweredQuestions($scope.components)));

      if ($scope.filter.compare !== 'NONE') {
        // refresh also the compare survey percentages
        Reports.generatePercentageBoxes(Utils.filterAnswersByGroup($scope.filter.selectedCompareSurvey.answers, $scope.filter.group), $scope.components, null, { situation: $scope.filter.situation, compareSections: true });
      }
    };


    // remove mouseover of other components, questions and overall
    $scope.overQuestion = (question) => {
      question.mouseover = function () {
        question.mouseoverdetails = true;
      };

      $scope.components.forEach(c => {
        c.questions.forEach(q => {
          if (q.key !== question.key) {
            delete q.mouseover;
            delete q.mouseoverdetails;
          }
        });
      });
    };

    $scope.back = () => {
      $location.path('/report-menu');
    };

    $scope.backgroundColor = (question) => {
      return !question.even ? { 'background-color': '#F5F5F5' } : {};
    };

    $scope.downloadPDF = () => {
      $scope.downloadingPdf = true;

      const isIE11 = window.ActiveXObject || 'ActiveXObject' in window;

      if (!isIE11) {
        Reports.togglePrintElements(true);

        $timeout(() => {
          Reports.downloadPDF({
            elementId: 'print-section',
            filename: $scope.headerTitle
          }).then((d) => {
            Reports.togglePrintElements(false);
            $scope.downloadingPdf = false;
          });
        });
      } else {
        // IE11
        modalInstance = vskoModalService.open({
          size: 'medium',
          template: require('../message/IE11-notSupported.html'),
          scope: $scope
        });
      }
    };

    $scope.onCancel = () => {
      // close modal
      modalInstance.dismiss('cancel');
    };

    /** Local functions * */

    /**
     * Join all the questions from the different components in one unique list.
     *
     * @param {*} components
     */
    function joinQuestions(components) {
      let questions = [];
      components.forEach(c => {
        c.questions.forEach(q => questions.push(q));
      });
      return questions;
    }

    let pageNr = 1;
    let questionsCount = 0;

    /**
     * Sort the given questions, best sum of possitive percentages on top
     *
     * @param {*} questions
     */
    function sortQuestions(questions) {
      let count = 0;
      pageNr = 1;
      questionsCount = 0;

      const resultQuestions = Utils.sortQuestionsPositiveTop(questions)
        .map(q => {
          // for background color
          q.even = count % 2 === 0;
          count++;
          return q;
        })
        .map(addPageBreaks);

      resultQuestions[resultQuestions.length - 1].pageBreak = { pageNr: pageNr, lastPage: true };

      $scope.totalPages = pageNr;

      return resultQuestions;
    }

    /**
     * Filter out those questions without answers in each component (#16981)
     *
     * @param {*} components
     * @returns components
     */
    function filterNotAnsweredQuestions(components) {
      return components.map(c => {
        c.questions = c.questions.filter(q => q.answers.length);
        return c;
      });
    }

    function addPageBreaks(q) {
      questionsCount++;

      if (pageBreakConfig
            && ((questionsCount >= pageBreakConfig.firstPageQuestions && pageNr === 1)
              || questionsCount >= pageBreakConfig.otherPagesQuestions)) {
        console.log('Page break:', questionsCount, pageNr, q);

        q.pageBreak = { pageNr: pageNr };
        pageNr++;
        questionsCount = 0;
      } else {
        delete q.pageBreak;
      }

      return q;
    }
  }];
