/**
 * Status page of the survey when it's already closed
 */
module.exports = [ '$scope', 'settings', '$location', '$translate', 'vskoModalService', 'WizardState', 'Survey', 'Utils',
  function ($scope, settings, $location, $translate, vskoModalService, WizardState, Survey, Utils) { //eslint-disable-line
    'use strict';

    WizardState.clearCurrentStep();

    $scope.headerTitle = 'Potentiële deelnemers';
    $scope.headerDescription = '';

    // init
    Survey.getSurvey().then(survey => {

      $scope.formattedEnddate = Utils.toString(new Date(survey.enddate));

      // sort participants in groups vertically
      survey.groups.forEach(group => {
        
        group.participants = group.participants.sort((p1, p2) => p1.email > p2.email ? 1 : -1);
        group.lines = Utils.splitArray(group.participants, group.participants.length > 8 ? 3 : 2);

        group.participants.forEach(participant => {
          participant.email = participant.name ? participant.name : participant.email; // this is only to support old surveys with name property
          participant.shortEmail = participant.email.length > 37 ? participant.email.substr(0, 35) + '...' : participant.email
        });
      });

      $scope.survey = survey;
    });

    $scope.back = () => {
      $location.path('/report-menu')
    }
  }];
