class Welcome {
  constructor($rootScope, $scope, Organisations) {
    this.$scope = $scope;
    this.rootScope = $rootScope;
    this.rootScope.welcomeScreen = true;
    this.Organisations = Organisations;

    $scope.$emit('wellbe_wizard_visibility', { visible: false });

    $scope.$emit('wellbe_header_logoVisibility', { visible: false });

    this.next = 'survey-selection';
  }

  startWizard(scope, e, arg) {
    // nothing to do?
  }

  $onInit() {
    return (async () => {
      this.hideHelp = true;
      let schools = await this.Organisations.getPreventionAdvisorResponsibilities();

      if (schools.length > 0) {
        this.hideHelp = false;
      }

      console.log('init welcome');
    })();
  }

  // injection here
  static get $inject() {
    return [
      '$rootScope',
      '$scope',
      'Organisations'
    ];
  }
}

module.exports = {
  template: require('./welcome.html'),
  controllerAs: 'ctrl',
  bindings: {
    test: '<'
  },
  controller: Welcome
};
