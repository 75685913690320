module.exports = ['$scope', '$location', '$timeout', 'vskoModalService', 'constants', 'Utils', 'Survey', 'Reports',
  function ($scope, $location, $timeout, vskoModalService, constants, Utils, Survey, Reports) { //eslint-disable-line

    'use strict';

    let modalInstance;

    $scope.headerTitle = 'Aandachtspunten';
    $scope.headerDescription = '';

    $scope.filter = {
      group: 'ALL',
      situation: constants.reportSituations[0].key,
      options: {}
    };

    let pageBreakConfig = {
      firstPageQuestions: 6,
      otherPagesQuestions: 11
    };

    // init
    Survey.getSurvey().then(survey => {
      // note: should be done before getting the answers
      $scope.components = Reports.generateComponentsStructure();

      $scope.formattedEnddate = Utils.toString(new Date(survey.enddate));

      $scope.survey = survey;

      Survey.getAnswers(survey.key, null, false).then(answers => {
        $scope.answers = answers;
        console.log('Survey answers:', answers);

        Reports.generatePercentageBoxes($scope.answers, $scope.components, null, { situation: $scope.filter.situation, debug: true });

        sortComponentsQuestions();

        // rule: filter groups with at least 5 participants with answers (#15165)
        $scope.filter.options.groups = $scope.survey.groups.filter(group => {
          var answers = $scope.answers.filter(answer => answer.group === group.key);
          var answersInGroup = answers.length;
          console.log('Answers in group ', group.name, answersInGroup);
          return answersInGroup >= 5;
        });
      });
    });

    // Filter functions

    $scope.updatedGroupFilter = () => {
      // get the group type from the survey
      $scope.filter.groupType = $scope.filter.group !== 'ALL' ? $scope.survey.groups.filter(g => g.key === $scope.filter.group)[0].type : null;

      // recreate the components structure showing only questions of the selected group
      $scope.components = Reports.generateComponentsStructure($scope.filter.groupType);

      Reports.generatePercentageBoxes(Utils.filterAnswersByGroup($scope.answers, $scope.filter.group), $scope.components, null, { situation: $scope.filter.situation });

      sortComponentsQuestions();
    };

    /**
     *  show component if at least one of its questions should be showed
     */
    $scope.showComponent = function (component) {
      let showComponent = false;

      if (!component.questions) {
        return;
      }

      component.questions.forEach(q => {
        if ($scope.showQuestion(q)) {
          showComponent = true;
        }
      });

      return showComponent;
    };

    /**
    * show the question in the report if it has >33% in the sum of negative percentage (TOT_NOT_AGREE, NOT_AGREE) (#15481)
    * and has some tip assigned
    */
    $scope.showQuestion = function (question) {
      if (question.sections && question.sections.length > 1) {
        let sumNegativePercentage = question.sections[0].percentage + question.sections[1].percentage;
        return (question.tipsOrganisation.length > 0 || question.tipsIndividuals.length > 0)
             && sumNegativePercentage >= 33;
      }

      return false;
    };

    $scope.changeView = function () {
      $location.path('/report-attention-points-by-tips');
    };

    // remove mouseover of other components, questions and overall
    $scope.overQuestion = function (question) {
      $scope.components.forEach(c => {
        c.questions.forEach(q => {
          if (q.key != question.key) {
            delete q.mouseover;
            delete q.mouseoverdetails;
          }
        });
      });

      question.mouseover = function () {
        question.mouseoverdetails = true;
      };
    };

    $scope.back = () => {
      $location.path('/report-menu');
    };

    $scope.backgroundColor = (even, index, q) => {
      // console.log('Row data:',index, even, q.even, q.componentCode, q.description)
      return !q.even ? { 'background-color': '#F5F5F5' } : {};
    };

    $scope.downloadPDF = () => {
      const isIE11 = window.ActiveXObject || 'ActiveXObject' in window;

      if (!isIE11) {
        Reports.togglePrintElements(true);

        $timeout(() => {
          Reports.downloadPDF({
            elementId: 'print-section',
            filename: $scope.headerTitle
          }).then((d) => {
            Reports.togglePrintElements(false);
          });
        });
      } else {
        // IE11
        modalInstance = vskoModalService.open({
          size: 'medium',
          template: require('../../message/IE11-notSupported.html'),
          scope: $scope
        });
      }
    };

    $scope.onCancel = () => {
      // close modal
      modalInstance.dismiss('cancel');
    };

    /** Local functions * */

    function sortComponentsQuestions() {
      pageNr = 1;
      questionsCount = 0;

      $scope.components.forEach(component => {
        let count = 0;
        questionsCount++; // the space of a compoent is equals to 2 questions

        // sort questions by biggest sum of negative percentage and filter those questions will at least 30% negative
        component.questions = Utils.sortQuestionsNegativeTop(component.questions)
          .filter(q => $scope.showQuestion(q))
          .map(q => {
            q.even = count % 2 === 0;
            count++;
            return q;
          })
          .map(addPageBreaks);
      });

      // last question of last component also has a break to show the pagination
      let lastComponentQuestions = $scope.components[$scope.components.length - 1].questions;
      if (lastComponentQuestions[lastComponentQuestions.length - 1]) {
        lastComponentQuestions[lastComponentQuestions.length - 1].pageBreak = { pageNr: pageNr, lastPage: true };
      } else {
        console.log('Check why it couldnt find last comp qst:', lastComponentQuestions);
      }

      $scope.totalPages = pageNr;
    }

    let pageNr = 1;
    let questionsCount = 0;

    function addPageBreaks(q) {
      // questionsCount = questionsCount + (q.tipsIndividuals.length + q.tipsOrganisation.length);
      questionsCount += (q.tipsIndividuals.length > 0 && q.tipsOrganisation.length > 0 ? 2 : 1);
      // questionsCount++;

      if (pageBreakConfig
            && ((questionsCount >= pageBreakConfig.firstPageQuestions && pageNr === 1)
              || questionsCount >= pageBreakConfig.otherPagesQuestions)) {
        console.log('Page break:', questionsCount, pageNr, q);

        q.pageBreak = { pageNr: pageNr };
        pageNr++;
        questionsCount = 0;
      } else {
        delete q.pageBreak;
      }

      return q;
    }
  }];
