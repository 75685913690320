module.exports = [ '$scope', '$q', '$location', 'WizardState', 'Survey', 'Utils',
  function ($scope, $q, $location, WizardState, Survey, Utils) { //eslint-disable-line
    'use strict';

    WizardState.clearCurrentStep();

    $scope.menurows = [
      [
        {img: '/img/lightbulb-grey-dark.svg', text: 'Aandachtspunten', next: '/report-attention-points'},
        {img: '/img/overview-grey-dark2.svg', text: 'Potentiële deelnemers', next: '/status-close'}
      ],
      [
        {img: '/img/report-grey-dark.svg', text: 'Dashboard Beleid', next: '/report-dashboard'},
        {img: '/img/report-grey-dark.svg', text: 'Dashboard Team', next: '/report-dashboard-team'}        
      ]
    ];

    Survey.getSurvey().then(survey => {

      $scope.formattedEnddate = Utils.toString(new Date(survey.enddate));

      $scope.survey = survey;

      $scope.school = Survey.getSchool();

      $scope.location = survey.location ? survey.location.display : $scope.school.locations.map(l => l.display).join(' - ');;
    });
    

    $scope.onChooseOption = function(option) {
      
      $location.path(option.next);      
    }

    $scope.back = () => {
      $location.path('/survey-selection');
    }
  }];
