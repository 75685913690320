/**
 * Created by guntherclaes on 09/11/17.
 */

module.exports = function ($stateProvider, $urlRouterProvider) {
  //Redirects
  $urlRouterProvider.when('', '/welcome');

  //404 if state not found
  $urlRouterProvider.otherwise(function ($injector) {
    $injector.get('$state').go('notFound', null, { location: false });
  });

  //Wizard pages
  $stateProvider
    .state('welcome', {
      url: '/welcome',
      // controller: require('../screen/welcome/welcome'),
      // template: require('../screen/welcome/welcome.html'),
      // reloadOnSearch: false
      component: 'welcome'
    })
    .state('school', {
      url: '/school',
      controller: require('../screen/school/school'),
      template: require('../screen/school/school.html'),
      reloadOnSearch: false
    })
    .state('locations', {
      url: '/locations',
      component: 'locations'
    })
    .state('directors', {
      url: '/directors',
      controller: require('../screen/directors/directors'),
      template: require('../screen/directors/directors.html'),
      reloadOnSearch: false
    })
    .state('survey-selection', {
      url: '/survey-selection',
      controller: require('../screen/survey-selection/survey-selection'),
      template: require('../screen/survey-selection/survey-selection.html'),
      reloadOnSearch: false
    })
    .state('intake', {
      url: '/intake',
      controller: require('../screen/wizard/intake/intake'),
      template: require('../screen/wizard/intake/intake.html'),
      reloadOnSearch: false
    })
    .state('motivation', {
      url: '/motivation',
      controller: require('../screen/wizard/intake/motivation'),
      template: require('../screen/wizard/intake/motivation.html'),
      reloadOnSearch: false
    })
    .state('enddate', {
      url: '/enddate',
      component: 'endDate'
      // controller: require('../screen/wizard/enddate/enddate'),
      // template: require('../screen/wizard/enddate/enddate.html'),
      // reloadOnSearch: false
    })
    .state('groups', {
      url: '/groups',
      controller: require('../screen/wizard/groups/groups'),
      template: require('../screen/wizard/groups/groups.html'),
      reloadOnSearch: false
    })
    .state('participants', {
      url: '/participants',
      controller: require('../screen/wizard/participants/participants'),
      template: require('../screen/wizard/participants/participants.html'),
      reloadOnSearch: false
    })
    .state('mail', {
      url: '/mail',
      controller: require('../screen/wizard/mail/mail'),
      template: require('../screen/wizard/mail/mail.html'),
      reloadOnSearch: false
    })
    .state('overview', {
      url: '/overview',
      controller: require('../screen/wizard/overview/overview'),
      template: require('../screen/wizard/overview/overview.html'),
      reloadOnSearch: false
    })  
    .state('status', {
      url: '/status',
      controller: require('../screen/status/status'),
      template: require('../screen/status/status.html'),
      reloadOnSearch: false
    })
    .state('reportMenu', {
      url: '/report-menu',
      controller: require('../screen/report/menu/menu'),
      template: require('../screen/report/menu/menu.html'),
      reloadOnSearch: false
    })
    .state('reportDashboard', {
      url: '/report-dashboard',
      controller: require('../screen/report/dashboard-main/dashboard-main'),
      template: require('../screen/report/dashboard-main/dashboard-main.html'),
      reloadOnSearch: false
    })
    .state('reportDashboardTeam', {
      url: '/report-dashboard-team',
      controller: require('../screen/report/dashboard-team/dashboard-team'),
      template: require('../screen/report/dashboard-team/dashboard-team.html'),
      reloadOnSearch: false
    })
    .state('reportAttentionPoints', {
      url: '/report-attention-points',
      controller: require('../screen/report/attention-points/by-components/attentionPoints'),
      template: require('../screen/report/attention-points/by-components/attentionPoints.html'),
      reloadOnSearch: false
    })
    .state('reportAttentionPointsByTips', {
      url: '/report-attention-points-by-tips',
      controller: require('../screen/report/attention-points/by-tips/attentionPointsByTips'),
      template: require('../screen/report/attention-points/by-tips/attentionPointsByTips.html'),
      reloadOnSearch: false
    })
    .state('reportStatus', {
      url: '/status-close',
      controller: require('../screen/report/status-close/statusClose'),
      template: require('../screen/report/status-close/statusClose.html'),
      reloadOnSearch: false
    })
    .state('notFound', {
      url: '/404',
      template: require('../screen/notFound/notFound.html')
    });

};
