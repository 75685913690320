module.exports = ['$translateProvider', '$stateProvider', '$urlRouterProvider',
  function ($translateProvider, $stateProvider, $urlRouterProvider) {
    'use strict';

    function getLocale() {
      return (
        window.navigator.language ||
        window.navigator.browserLanguage ||
        window.navigator.systemLanguage ||
        window.navigator.userLanguage || ''
      ).split('-').join('_');
    }

    $translateProvider.useStaticFilesLoader(
      {
        prefix: 'js/config/translations/',
        suffix: '.json'
      }
    ).determinePreferredLanguage(
      function () {
        var language = getLocale().toLowerCase().substring(0, 2);

        if (language && language.indexOf('nl') !== -1) {
          return 'nl';
        }

        return 'en';
      }
    ).fallbackLanguage('en').useSanitizeValueStrategy('sanitize');

    require('./routes')($stateProvider, $urlRouterProvider);
  }
];
