
require('./welcome/welcome.scss');

angular.module('appScreens', [])

.component('appRoot', require('./appRoot'))


.component('welcome', require('./welcome/welcome.js'))

.component('locations', require('./locations/locations.js'))

.component('endDate', require('./wizard/enddate/enddate.js'));


module.exports = 'appScreens';
